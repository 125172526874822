import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import auth from './auth'
import experience from './experience'
import places from './places'
import sections from './sections'
import jobs from './jobs'
import skills from './skills'
import applicants from './applicants'
import works from './works'
import services from './services'
import utilities from './utilities'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    jobs,
    experience,
    places,
    sections,
    applicants,
    services,
    works,
    skills,
    utilities
  },
  strict: process.env.DEV,
})
