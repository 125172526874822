// import axios from '@/plugins/axios';
import i18n from "@/libs/i18n";
import router from "@/router";
export default {
  async loadFile({ commit }, file) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(`https://backend-wameed.wameedprojects.com/api/v1/file/download/${file}`, {
        responseType: "blob",
      })
      .then((respo) => {
        if (respo.status === 200) {
          commit("loadingFinish");
          var blob = respo.data;

          var a = window.document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = file;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
};
