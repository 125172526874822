import store from "@/store/index";
import Vue from "vue";
export default function middleware({ to, next }) {
  let loginData = Vue.$cookies.get("login-data", { parseJSON: true });
  if (loginData) {
    if (loginData.remember_me) {
      var date = Date.now();
      if (loginData.exp_date <= date) {
        store.dispatch("refreshToken", loginData.refresh_token).then(() => {
          if (to.name == "login")
            return next({ name: "dashboard", params: { lang: "ar" } });
            else
      return next()
        });
      } else {
        if (to.name == "login")
          return next({ name: "dashboard", params: { lang: "ar" } });
          else
      return next()
      }
    } else {
      if (to.name == "login")
        return next({ name: "dashboard", params: { lang: "ar" } });
        else
      return next()
    }
  } else {
    if (to.name != "login")
      return next({ name: "login", params: { lang: "ar" } });
      else
      return next()
  }
}
