export default {
  setJobs: (state, data) => {
    state.allJobs = data;
  },
  setJobDetails: (state, data) => {
    state.jobDetails = data;
  },
  setJobChangedState: (state) => {
    state.jobDetails.state = state.jobDetails.state==0?1:0;
  },
};
