// import axios from '@/plugins/axios';
import i18n from "@/libs/i18n";
import router from "@/router";
export default {
  async submitLogin({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post(`auth/login`, data)
      .then((respo) => {
        if (respo.status === 200) {
          var date = new Date();
          var responseData = {
            ...respo.data.data.content,
            exp_date: date.setSeconds(
              date.getSeconds() + respo.data.data.content.expires_in
            ),
          };

          this._vm.$http.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${responseData.token}`;

          this._vm.$cookies.set(
            "login-data",
            JSON.stringify(responseData),
            null,
            null,
            "localhost",
            true

            // httpOnly:true,
            // sameSite: true
          );
          this._vm.$cookies.set(
            "login-data",
            JSON.stringify(responseData),
            null,
            null,
            "dashboard.wameed.co",
            true

            // httpOnly:true,
            // sameSite: true
          );

          let loginData = this._vm.$cookies.get("login-data", {
            parseJSON: true,
          });
          if (loginData) {
            // dispatch('loadUserDetail')
            // commit('setLoggedInuser', loginData)
            router.push({ name:'dashboard',params:{lang:i18n.locale} });
          }

          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async refreshToken({ commit }, data) {
    commit("loadingStart", null);

    await this._vm.$http
      .post(`refresh`, null, { headers: { refresh_token: data } })
      .then((respo) => {
        if (respo.status === 200) {
          delete this._vm.$http.defaults.headers.common["Authorization"];
          var date = new Date();
          var responseData = {
            ...respo.data.data,
            exp_date: date.setSeconds(
              date.getSeconds() + respo.data.data.expires_in
            ),
          };
          this._vm.$http.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${responseData.token}`;
          // this._vm.$cookies.remove('login-data')
          // this._vm.$cookies.remove('login-data', '/' + i18n.locale, 'dashboard.wameed.co',true )
          this._vm.$cookies.set(
            "login-data",
            JSON.stringify(responseData),
            null,
            "/" + i18n.locale,
            "dashboard.wameed.co",
            true

            // httpOnly:true,
            // sameSite: true
          );
          // let loginData = this._vm.$cookies.get('login-data', { parseJSON: true })
          // if (loginData) {
          // dispatch('loadUserDetail')
          // commit('setLoggedInuser', loginData)
          // router.push({ path: '/' + i18n.locale + '/dashboard' })

          // }

          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async checkLoggedIn({ commit, dispatch }) {
    // let loginData = JSON.parse(localStorage.getItem('loginData'))\
    let loginData = this.$cookies.get("login-data", { parseJSON: true });
    if (loginData) {
      if (
        this._vm.$http.defaults.headers.common["Authorization"] === undefined
      ) {
        this._vm.$http.defaults.headers.common["Authorization"] =
          "Bearer " + loginData.token;
      }
      commit("setLoggedInuser", loginData);

      dispatch("loadUserDetail");
    }
  },
  async logout({ commit }) {
    this._vm.$cookies.remove("login-data");
    this._vm.$cookies.remove(
      "login-data",
      null,
      "dashboard.wameed.co",
      true

      // httpOnly:true,
      // sameSite: true
    );
    this._vm.$cookies.remove(
      "login-data",
      null,
      "dashboard.wameed.co",
      true

      // httpOnly:true,
      // sameSite: true
    );

    // this._vm.$cookies.remove('login-data', {
    //     domain: 'wameed-restaurant.herokuapp.com',
    //     path: '/ar',
    //     secure: true,
    //     sameSite: true,
    //     // httpOnly:true,
    // })
    // let loginData = JSON.parse(localStorage.getItem('loginData'))\
    let loginData = this._vm.$cookies.get("login-data", { parseJSON: true });
    if (!loginData) {
      this._vm.$http.defaults.headers.common["Authorization"] = undefined;

      // commit("setLoggedOutuser");
      router.push({ name:'login',params:{lang:i18n.locale} });
    }
  },
};
