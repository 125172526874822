// import axios from '@/plugins/axios';
import i18n from "@/libs/i18n";
import router from "@/router";
export default {
  async loadJobs({ commit },search) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(`jobs?search=${search}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setJobs", respo.data.data.content);

          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async addJob({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post(`jobs`, data)
      .then((respo) => {
        if (respo.status === 200) {
          router.push({ name:'jobs', params: {lang:i18n.locale} });
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadJobDetails({ commit }, jobId) {
    commit("loadingStart", null);
    await this._vm.$http
      .get(`jobs/${jobId}`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setJobDetails", respo.data.data.content);

          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async updateJob({ commit }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .put(`jobs/${data.jobId}`, data.content)
      .then((respo) => {
        if (respo.status === 200) {
          router.push({ name:'job-details', params: {lang:i18n.locale,id:data.jobId} }); 
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async updateStateJob({ commit, state }, jobId) {
    commit("loadingStart", null);
    await this._vm.$http
      .put(`jobs/${jobId}/change-state`)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setJobChangedState");
          commit(
            "successMessage",
            state.jobDetails.state == 0 ? "job_shown" : "job_hidden"
          );
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async deleteJob({ commit }, jobId) {
    commit("loadingStart", null);
    await this._vm.$http
      .delete(`jobs/${jobId}`, jobId)
      .then((respo) => {
        if (respo.status === 200) {
          router.push({ name:'jobs',params:{lang:this.$i18n.locale} });
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
};
