// import Vue from 'vue'

// // Lib imports
// import axios from 'axios'

// Vue.prototype.$http = axios
// s
import i18n from "@/libs/i18n";
import  store  from '@/store/index'
import Vue from 'vue'
import axios from 'axios';
Vue.prototype.$http = axios
Vue.prototype.$http.defaults.baseURL = 'https://backend-wameed.wameedprojects.com/api/v1/super/'
Vue.prototype.$http.defaults.headers.common["locale"] = i18n.locale
Vue.prototype.$http.defaults.headers.post['Content-Type'] = 'application/json;  charset=UTF-8'

Vue.prototype.$http.interceptors.request.use((request) => {
  // Update token axios header
  var authToken = request.headers.common["Authorization"]
  if (!authToken) {
    let loginData = Vue.$cookies.get("login-data", { parseJSON: true });
    if (loginData) {
      request.headers.common["Authorization"] = "Bearer " + loginData.token;

    }
  }
  return request;
});

Vue.prototype.$http.interceptors.response.use(
  
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 400) {
     store.commit('failMessage', error.response.data.code_error?error.response.data.code_error:'error')
 
    }
   
    if (error.response.status === 401) {
      
      store.dispatch('logout');
      store.commit('failMessage',error.response.status)
     

    }
    if (error.response.status === 404
      ||error.response.status === 422
      || error.response.status === 403
       ||  error.response.status === 500 ) {
   

      store.commit('failMessage',error.response.status)

    }
   
  }
);

