import i18n from "@/libs/i18n";
import middleware from "../middleware";
export default [
  {
    path: "",
    name: "home",
    redirect: `/${i18n.locale}/dashboard`,
  },
  {
    path: "login",
    name: "login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      middleware: middleware,
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "dashboard",
    name: "dashboard",
    component: () => import("@/views/pages/dashboard/dashboard.vue"),
    meta: {
      middleware: middleware,
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "dashboard",
          to: "dashboard",
          active: true,
        },
      ],
    },
  },
  {
    path: "applicants",
    component: {
      render(c) {
        return c("router-view");
      },
    },

    children: [
      {
        path: "",
        name: "applicants",
        component: () => import("@/views/pages/applicants/index.vue"),
        meta: {
          middleware: middleware,
          breadcrumb: [
            {
              text: "applicants",
              to: "applicants",
              active: true,
            },
          ],
        },
      },
      {
        path: ":id",
        name: "applicant-details",
        component: () => import("@/views/pages/applicants/details.vue"),
        meta: {
          middleware: middleware,
          breadcrumb: [
            {
              text: "applicants",
              to: "applicants",
            },
            {
              text: "applicant_details",
              to: "applicant-details",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "training-applicants",
    component: {
      render(c) {
        return c("router-view");
      },
    },

    children: [
      {
        path: "",
        name: "training-applicants",
        meta: {
          middleware: middleware,
          breadcrumb: [
            {
              text: "training",
              to: "training-applicants",
              active: true,
            },
          ],
        },
      },
     
    ],
  },
  {
    path: "works",
    component: {
      render(c) {
        return c("router-view");
      },
    },

    children: [
      {
        path: "",
        name: "works",
        component: () => import("@/views/pages/works/index.vue"),
        meta: {
          middleware: middleware,
          breadcrumb: [
            {
              text: "works_requests",
              to: "works",
              active: true,
            },
          ],
        },
      },
      {
        path: ":id",
        name: "work-details",
        component: () => import("@/views/pages/works/details.vue"),
        meta: {
          middleware: middleware,

          breadcrumb: [
            {
              text: "works_requests",
              to: "works",
            },
            {
              text: "work_details",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "chat",
    component: {
      render(c) {
        return c("router-view");
      },
    },

    children: [
      {
        path: "",
        name: "chat",
        meta: {
          middleware: middleware,
          breadcrumb: [
            {
              text: "chat",
              to: "chat",
              active: true,
            },
          ],
        },
      },
     
    ],
  },
  {
    path: "mails",
    component: {
      render(c) {
        return c("router-view");
      },
    },

    children: [
      {
        path: "",
        name: "mails",
        meta: {
          middleware: middleware,
          breadcrumb: [
            {
              text: "mails",
              to: "mails",
              active: true,
            },
          ],
        },
      },
     
    ],
  },
  {
    path: "settings",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "settings",
        component: () => import("@/views/pages/settings/settings.vue"),
        meta: {
          middleware: middleware,
          breadcrumb: [
            {
              text: "settings",
              to: "settings",
              active: true,
            },
          ],
        },
      },
      {
        path: "jobs",
        component: {
          render(c) {
            return c("router-view");
          },
        },

        children: [
          {
            path: "",
            name: "jobs",
            component: () => import("@/views/pages/settings/jobs/index.vue"),
            meta: {
              middleware: middleware,
              breadcrumb: [
                {
                  text: "settings",
                  to: "settings",
                },
                {
                  text: "jobs",
                  to: "jobs",
                  active: true,
                },
              ],
            },
          },
          {
            path: "add-job",
            name: "add-job",
            component: () => import("@/views/pages/settings/jobs/addJob.vue"),
            meta: {
              middleware: middleware,
              breadcrumb: [
                {
                  text: "settings",
                  to: "settings",
                },
                {
                  text: "jobs",
                  to: "jobs",
                },
                {
                  text: "adding_new_job",
                  to: "add-job",
                  active: true,
                },
              ],
            },
          },
          {
            path: ":id",
            name: "job-details",
            component: () => import("@/views/pages/settings/jobs/details.vue"),
            meta: {
              middleware: middleware,
              breadcrumb: [
                {
                  text: "settings",
                  to: "settings",
                },
                {
                  text: "jobs",
                  to: "jobs",
                },
                {
                  text: "job_details",
                  text: "job_details",
                  active: true,
                },
              ],
            },
          },

          {
            path: ":id/update",
            name: "update-job",
            component: () =>
              import("@/views/pages/settings/jobs/updateJob.vue"),
            meta: {
              middleware: middleware,
              breadcrumb: [
                {
                  text: "settings",
                  to: "settings",
                },
                {
                  text: "jobs",
                  to: "jobs",
                },
                {
                  text: "job_details",
                  to: "job-details",
                },
                {
                  text: "update_job",
                  to: "update-job",
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },
];
