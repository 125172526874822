import Vue from 'vue'
import arrowDown from './arrow-down.svg'
import arrowPrev from './arrow-prev.svg'
import arrowNext from './arrow-next.svg'
import bill from './bill.svg'
import chat from './chat.svg'
import check from './check.svg'
import close from './close.svg'
import emails from './emails.svg'
import applicants from './applicants.svg'
import eyeoff from './eyeoff.svg'
import eyeon from './eyeon.svg'
import filter from './filter.svg'
import home from './home.svg'
import logout from './logout.svg'
import logout2 from './logout2.svg'
import menu from './menu.svg'
import messages from './messages.svg'
import moon from './moon.svg'
import nodata from './nodata.svg'
import search from './search.svg'
import setting from './setting.svg'
import trainging from './trainging.svg'
import logoDark from './logo-dark.svg'
import userMale from './user-male.svg'
import skills from './skills.svg'
import bag from './bag.svg' 
import jobsBag from './jobs-bag.svg'
import location from './location.svg'
import clock from './clock.svg'
import file from './file.svg'
import download from './download.svg'
import company from './company.svg'
import government from './government.svg'
import individual from './individual.svg'
import organization from './organization.svg'

Vue.component('arrow-down-icon',arrowDown)
Vue.component('bill-icon',bill)
Vue.component('chat-icon',chat)
Vue.component('check-icon',check)
Vue.component('close-icon',close)
Vue.component('emails-icon',emails)
Vue.component('applicants-icon',applicants)
Vue.component('eyeoff-icon',eyeoff)
Vue.component('eyeon-icon',eyeon)
Vue.component('filter-icon',filter)
Vue.component('home-icon',home) 
Vue.component('logout-icon',logout)
Vue.component('logout2-icon',logout2)
Vue.component('menu-icon',menu)
Vue.component('messages-icon',messages)
Vue.component('moon-icon',moon)
Vue.component('nodata-icon',nodata)
Vue.component('search-icon',search)
Vue.component('setting-icon',setting)
Vue.component('trainging-icon',trainging)
Vue.component('logo-dark',logoDark)
Vue.component('user-male',userMale)
Vue.component('skills-icon',skills)
Vue.component('bag-icon',bag)
Vue.component('jobs-bag-icon',jobsBag)
Vue.component('location-icon',location)
Vue.component('clock-icon',clock)
Vue.component('file-icon',file)
Vue.component('download-icon',download)
Vue.component('arrow-prev-icon',arrowPrev)
Vue.component('arrow-next-icon',arrowNext)
Vue.component('company-icon',company)
Vue.component('government-icon',government)
Vue.component('individual-icon',individual)
Vue.component('organization-icon',organization)
